$nav-padding: 1.2rem;
$nav-min-height: 3.75rem;
$nav-item-outline-margin--mobile: 6px;
$nav-item-outline-margin--desktop: 12px;

.navbar{
  background: $bg;
  min-height: $nav-min-height;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.25);
  .navbar-item{
    color: $white;
    padding: $nav-padding;
    &:hover{
      background: none;
      color: $primary;
    }

    &.outline{
      transition: color $speed;
      color: $primary;

      &:before{
        content: '';
        display: block;
        position: absolute;
        top: $nav-item-outline-margin--mobile;
        left: 2 * $nav-item-outline-margin--mobile;
        width: calc(100% - 4 * #{$nav-item-outline-margin--mobile});
        height: calc(100% - 2 * #{$nav-item-outline-margin--mobile});
        border: 1px solid $primary;
        transition: border-color $speed, background-color $speed;
      }
      &:hover{
        &:before{
          border-color: $primary;
          z-index: 0;
        }
      }
    }
  }
  .navbar-brand{
    .navbar-item{
      text-transform: uppercase;
      color: $primary;
      font-size: 1.3em;
      line-height: 1em;
      letter-spacing: 0.03em;
      font-family: $font-family-tech;
      padding-left: 1.4rem;
    }
  }
  .navbar-burger{
    height: $nav-min-height;
    height: $nav-min-height;
  }  
}



@media only screen and (max-width: $nav-desktop - 1px) {
  .navbar-menu{
    background-color: #141414;
  }

  .cr-nav{
    background-color: transparent;
  }

  .navbar .navbar-item{
    padding: 1rem;
    font-size: 1.2em;
    text-align: center;
  }
}

@media only screen and (min-width: $nav-desktop) {
  .navbar {
    .navbar-end{
      padding-right: $nav-padding;
    }
    .navbar-item{
      &.outline{
        transition: color $speed;
        margin-left: $nav-item-outline-margin--desktop;
        &:before{
          top: $nav-item-outline-margin--desktop;
          left: 0;
          width: 100%;
          height: calc(100% - 2 * #{$nav-item-outline-margin--desktop});
        }
        &:hover{
          color: $bg;
          &:before{
            background-color: $primary;
            z-index: -1;
          }
        }
      }
    }
  }
}
