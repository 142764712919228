:root{
  @media only screen and (max-width: $tablet) {
    font-size: 14px;
  }
}

html{
  background: $bg;
}

body{
  font-family: $font-family-default;
  color: #333;
  padding-bottom: 60px;
}

h1{
  font-family: $font-family-header;
}
h2, h3{
  font-family: $font-family-accent;
}
h1{
  color: #8EF1F3;
  font-size: 36px;
}

h2, h3{
  color: $white;
}
h2{
  border: 1px solid $white;
  display: inline-block;
  font-size: 2.1rem;
}
h3{
  font-size: 1.8rem;
}
h4{
  font-size: 1.1rem;
}

.accent, .accent a{
  color: $primary;
}

.accent-outline{
  border-color: $primary;
}

.secondary-color, .secondary-color a{
  color: $secondary;
}
.secondary-outline{
  border-color: $secondary;
}

.tertiary-color, .tertiary-color a{
  color: $tertiary;
}
.tertiary-outline{
  border-color: $tertiary;
}

.tech{
  font-family: $font-family-tech;
  font-weight: 400;
}

p{
  font-size: 1.04em;
}
section{
  padding: 3rem 0 2rem;
  position: relative;
  h2{
    padding: 0.2em 0.6em;
    margin-bottom: 0.8em;
    @media only screen and (max-width: $mobile) {
      font-size: 1.8rem;
    }
  }
  h3{
    margin-top: 2em;
    margin-bottom: 0.4em;
    line-height: 1.2em;
  }
  h2 + h3{
    margin-top: 0.4em;
  }
  .tech{
    opacity: 0.3;
    &:before{
      content: '>>> ';
    }
    &:after{
      content: ' >>>';
    }
  }
  p.tech{
    margin-top: 3rem;
  }
  .emphasis{
    font-size: 1.14em;
    font-weight: 500;
  }
  &.section--dark{
    color: $white;
  }
  &.section--light{
    background: #ffffff;
    h2, h3{
      color: $black;
      border-color: $black;
    }
  }
}
.center{
  text-align: center;
}
.cr__content{
  max-width: 700px;
  margin: 0 auto;
  padding: 0 2rem;
  p{
    margin: 0 0 2rem;
  }
}

.wrapper{
  max-width: 1240px;
  margin: 0 auto;
}


.anchor{
    height: 0;
    margin-top: -100px;
    padding-top: 100px;
}

a{
  color: $secondary;
  transition: color $speed, border-color $speed;
  
  .section--light & {
    &:hover{
      color: $primary-darken;
    }
  }

  .section--dark & {
    &:hover{
      color: $primary-darken;
    }
  }

  &.underline{
    padding-bottom: 0.2rem;
    border-bottom: 1px solid $secondary;
    .section--light & {
      border-color: $secondary;
    }
    &:hover, .section--light &:hover{
      color: $primary-darken;
      border-color: $primary-darken;
    }
  }

  &.btn{
    color: $white;
    display: inline-block;
    transition: color $speed, border-color $speed, background-color $speed;
    border: 1px solid $white;
    padding: 0.5rem 1rem;
    .section--light & {
      color: $black;
      border-color: $black;
    }
    &:hover, .section--light &:hover{
      border-color: $primary-darken;
      color: $primary-darken;
    }
  }
}

// HERO
.section--home{
  margin-top: 50px;
  padding-top: 35px;
  @media only screen and (max-width: $tablet) {
    padding-top: 24px;
  }
  @media only screen and (max-width: $mobile) {
    padding-top: 15px;
  }
  .home__image{
    background-image: url("../img/newhero.gif");
    background-size: cover;
    background-position: center;
    height: 450px;

    @media only screen and (max-width: $tablet) {
      height: 300px;
    }

    @media only screen and (max-width: $mobile) {
      height: 200px;
    }
  }
  .home__content{
    padding: 0 1rem;
  }
  .home__heading{
    font-size: 8.2rem;
    font-weight: 500;
    letter-spacing: 0.06em;
    color: $primary;
    text-transform: uppercase;
    line-height: 1em;
    margin-top: -0.82em;
    margin-bottom: 0.3em;

    @media only screen and (max-width: $tablet) {
      font-size: 5.2rem;

      letter-spacing: 0.03em;
    }

    @media only screen and (max-width: $mobile) {
      font-size: 3.6rem;
    }
  }
  .home__subtitle{
    font-family: $font-family-body;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.8rem;
    letter-spacing: -0.03em;
    margin-bottom: 1.6em;

    & + h3{
      font-size: 1.55rem;
      @media only screen and (max-width: $mobile) {
        font-size: 1.4rem;
      }
    }

    @media only screen and (max-width: $tablet) {
      margin-bottom: 1em;
    }

    @media only screen and (max-width: $mobile) {
      font-size: 1.5rem;
    }
  }
}
// VENUE
.section--venue{
  overflow: visible;
  position: relative;
  .wrapper{
    margin-top: 20px;
    @media only screen and (max-width: $tablet) {
      padding: 0 15px;
    }
  }
  .bg{
    position: absolute;
    z-index: -1;
    height: 40%;
    width: 100%;
    background: $white;
    bottom: 0;
  }
  .venue{
      width: 100%;
      min-height: 350px;
      position: relative;
      background-image: url("../img/holdingco-bg.jpg");
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0px 5px 15px rgba(0,0,0,0.25);
      transition: all ease-in-out .25s;
      top: 0;

      a{
        font-weight: 600;
        font-size: 18px;
      }
  }
}

// about
.section--about{
  background: $white;
  h2, h3{
    color: $black;
    border-color: $black;
  } 
}

.section--accommodations{
  min-height: 450px;
  padding-top: 0;

  .bg{
    z-index: -1;
    width: 100%;
    height: 450px;
    background-image: linear-gradient(to top, $bg 0, transparent 32%), url("../img/la.jpg");
    background-size: cover;
    background-position: center top;
    margin: -2.4rem 0 -8.4rem;

  }
  h2{
    margin: 0 0 2.2rem;
  }
  .card{
    @media only screen and (max-width: $tablet) {
      margin: 0 15px;
    }
    .card-content{
      background: $black;
    }
    h4{
      margin-bottom: 0;
      color: $white;
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.03em;
    }
  }
  .card-image{
    .image{
      overflow: hidden;
      img{
        height: auto;
      }
    }
  }
  p:last-of-type{
    margin-bottom: 3rem;
  }
}


.section--map{
  padding-top: 0;

  .map-wrapper{
    background: #ffffff;
    width: 1240px;
    max-width: calc(100% - 30px);
    margin: 0 auto;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.25);
    @media only screen and (max-width: $tablet) {
      margin: 0 15px;
    }
    > div{
      width: 100%;
    }
  }
  .bg{
    position: absolute;
    z-index: -1;
    height: 50%;
    width: 100%;
    background: $white;
    bottom: 0;
  }
}

.section--travel{
  background: #ffffff;
  h2, h3{
    color: $black;
    border-color: $black;
  }
}

.section--details{
  .time{
    font-weight: 700;
    text-decoration: underline;
  }
}

.section--registry{
  padding: 60px 0;
  background: white;
}

.splash-image{
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: -2px 5px 8px -1px rgba(0, 0, 0, 0.3);
}
